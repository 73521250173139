import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-892d166e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 0,
  class: "c-nutrition-empty-state-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_NutritionalPlanHeader = _resolveComponent("NutritionalPlanHeader")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_MealFoodsCard = _resolveComponent("MealFoodsCard")!
  const _component_AlternativeMealModal = _resolveComponent("AlternativeMealModal")!
  const _component_NutritionalPlanDeleteModal = _resolveComponent("NutritionalPlanDeleteModal")!

  return (_ctx.loadingNutritionalPlan)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_ctx.nutritionalPlan && _ctx.nutritionalPlanFormDto)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_NutritionalPlanHeader, {
            "loading-nutritional-plan": _ctx.loadingNutritionalPlan,
            title: _ctx.title,
            "tabs-menu": _ctx.tabsMenu,
            breadcrumb: _ctx.breadcrumb,
            "user-can-edit": _ctx.userCanEdit,
            "nutritional-plan-enabled": _ctx.nutritionalPlanFormDto.enabled,
            onNutritionalPlanDeleted: _ctx.onDeleteNutritionalPlan,
            onNutritionalPlanEnabledChanged: _ctx.onNutritionalPlanEnabledChanged
          }, null, 8, ["loading-nutritional-plan", "title", "tabs-menu", "breadcrumb", "user-can-edit", "nutritional-plan-enabled", "onNutritionalPlanDeleted", "onNutritionalPlanEnabledChanged"]),
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.nutritionalPlan?.defaultMeals)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_EmptyState, {
                    heading: _ctx.$t('nutrition.menuTemplate.no-data'),
                    text: _ctx.$t('nutrition.menuTemplate.complete-patient-data'),
                    secondary: false,
                    icon: "icon-file_blank_outline"
                  }, null, 8, ["heading", "text"])
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.nutritionalPlan?.defaultMeals, (meal) => {
                  return (_openBlock(), _createBlock(_component_MealFoodsCard, {
                    key: meal.id,
                    nutritionalPlan: _ctx.nutritionalPlan,
                    mealMenu: meal,
                    userCanEdit: true,
                    "show-completed-meal-tag": false,
                    onOnUpdateMenuFood: _ctx.onMenuFoodUpdate,
                    onOnCreateAlternative: _ctx.onCreateAlternativeMeal
                  }, null, 8, ["nutritionalPlan", "mealMenu", "onOnUpdateMenuFood", "onOnCreateAlternative"]))
                }), 128)),
            _createVNode(_component_AlternativeMealModal, {
              ref: "alternativeMealModalRef",
              patientId: _ctx.patientId,
              onCreatedAlternativeMeal: _ctx.fetchNutritionalPlan
            }, null, 8, ["patientId", "onCreatedAlternativeMeal"]),
            _createVNode(_component_NutritionalPlanDeleteModal, {
              ref: "nutritionalPlanDeleteModalRef",
              nutritionalPlanId: _ctx.nutritionalPlanFormDto.id,
              patientId: _ctx.patient.id,
              onNutritionalPlanDeleted: _ctx.onNutritionalPlanDeleted
            }, null, 8, ["nutritionalPlanId", "patientId", "onNutritionalPlanDeleted"])
          ])
        ], 64))
      : _createCommentVNode("", true)
}